<template>
    <div class="content">
          <!-- <img class="logo" src="../assets/image/321.png" alt=""> -->
          <div class="login_content">
              <h2>尙医统筹问诊平台</h2>
              <div class="content_login">
                  <h2>注册</h2>
                  <div class="content_input-right-input">
                      <el-input  prefix-icon="el-icon-user" v-model="UserName" @keyup.enter.native="submit" clearable placeholder="手机号"></el-input>
                      <!-- <el-input prefix-icon=" el-icon-lock" style="margin-top:8%"  v-model="PassWord1" clearable show-password placeholder="请输入密码"></el-input>
                      <el-input prefix-icon=" el-icon-lock" style="margin-top:8%"  v-model="PassWord2" clearable show-password placeholder="请再次输入密码"></el-input> -->
                      <el-input style="margin-top:8%" placeholder="请输入验证码" v-model="code" class="input-with-select">
                            <el-button slot="append" @click="getKey" icon="el-icon-key">{{btntable}}</el-button>
                        </el-input>
                      <div class="dis">
                            <el-button style="width:40%;"  @click="Return">返 回</el-button>
                            <el-button style="width:40%;background: #f8d347; color: #000;height: 60px;"  @click="modify">注 册</el-button>
                        </div>
                  </div>
              </div>
          </div>
      </div>
  </template>
  <script>
  import module_function from '../utils/login.js'
  export default {
       data() {
          return {
              UserName:'',
              PassWord1:'',
              PassWord2:'',
              btntable:'获取验证码',
              code:''
          }
        },
        methods:{
            getKey(){
                if(this.UserName == ''){
                    this.$notify.error({
                        message: '请输入手机号码'
                    });
                    return
                }
                this.$api_token.article.sms(this.UserName).then(res=>{
                    this.$message({
						message: '验证码发送成功!',
						type: 'success'
					});
                })
            },
            modify(){
                let data = {
                    user:{
                        username:this.UserName,
                        password:this.PassWord1
                    },
                    code:this.code
                }
                this.$api_token.article.register(data).then(res=>{
                    // module_function.sms_login(this.UserName,this.code,'/')
                    module_function.sms_login(this.UserName,this.code,'/index','usercreated')
                })
            },
            Return(){
                this.$router.back(-1);
            }
        },
        created(){
        }
  }
  </script>
  <style lang="less" scoped>
  .dis{
    display: flex;
    justify-content: space-between;
    margin-top: 8%;
  }
  .logo{
      width: 200px;
      height: 62px;
      position: absolute;
      left: 2%;
      top: 2%;
  }
  .content{
      width: 100%;
      // min-width: 1600px;
      min-height: 789px;
      height: 100%;
      background-image: url('../assets/image/logonbg.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
  }
  .login_content{
      width: 80%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
  }
  .login_content>h2{
      width: 200%;
      position: relative;
      left: -50%;
      color: #fff;
      text-align: center;
      padding: 20px;
      font-weight: 600;
      font-size: 1.8em;
      margin-top: 40%;
      letter-spacing:8px;
  }
  .footer_{
      width: 200%;
      position: fixed;
      color: #fff;
      // text-align: center;
      // letter-spacing:2px;
      // margin-top: 20%;
      bottom: 20px;
  }
  .content_login{
      background: #fff;
      border-radius: 6px;
      padding: 20px;
  }
  .content_login>h2{
      padding: 20px;
      padding-left: 0;
      color: #000;
  }
  .content_input_logon{
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      margin-top: 8%;
      padding-bottom: 10%;
  }
  // .content_input_img{
  // 	width: 40%;
  // }
  // .content_input_img img{
  // 	width: 100%;
  // }
  // .content_input .absolute_{
  // 	position: absolute;
  //     left: 4%;
  //     bottom: 5%;
  // }
  // .content_input-right{
  // 	position: absolute;
  // 	left: 50%;
  // 	width: 50%;
  // 	height: 100%;
  // 	padding: 6%;
  // }
  // .content_input-right-input{
  // 	width: 70%;
  // 	margin: 0 auto;
  // 	margin-top:10%;
  // }
  // .content_input-right-title{
  // 	display: flex;
  // 	justify-content: center;
  // 	align-items: center;
  // 	.content_input-right-title-img{
  // 		width: 100%;
  // 		img{
  // 			width: 100%;
  // 		}
  // 	}
  // 	.content_input-right-title-text{
  // 		// text-align: center;
  // 		font-size: 40px;
  // 		font-weight: 600;
  // 		margin-left: 2%;
  // 		font-family:"Hiragino Sans GB";
  // 		height: 119px;
  // 		.content_input-right-title-text2{
  // 			margin-top: 8px;
  // 			font-size: 23px;
  // 			font-weight: 500;
  // 			color: rgb(150, 150, 150);
  // 			letter-spacing:8px;
  // 		}
  // 	}
      
  // }
  </style>